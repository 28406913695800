import type { JSX } from 'react';

import { Translate, TranslatePlural } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { LoadMoreButton } from 'src/app/pages/petitionGamma/details/shared/components';
import { useLazyLoadedListFocus } from 'src/app/shared/hooks/focus';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { usePetitionUpdates } from '../../hooks/updates';
import { SECTION_ID_MAPPING, useScrollToSection } from '../../hooks/useScrollToSection';

import { Update } from './components/Update';
import { NewUpdateCtaContainer } from './NewUpdateCta';

function UpdatesContainerInner(): JSX.Element | null {
	const updatesState = usePetitionUpdates();
	const { containerRef, loadMoreRef, loadingRef, getListItemRef, recordListEvent, lastEvent } =
		useLazyLoadedListFocus();

	if (isLoading(updatesState)) {
		return <Loader size="m" mx="auto" my={24} />;
	}

	if (isError(updatesState)) {
		// TODO replace with another design?
		return (
			<InlineMessage size="large" variant="error" my={16}>
				<Translate value="fe.errors.generic_try_again" />
			</InlineMessage>
		);
	}

	const { updates, loadingMore, fetchMore } = updatesState;

	return (
		<div ref={containerRef}>
			{/* using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
			<Box as="ul" role="list">
				{updates.map((update, index) => (
					<Box as="li" key={update.id}>
						{lastEvent.type === 'loadMore' && index === lastEvent.firstNewItemIndex && (
							<VisuallyHidden role="alert">
								<TranslatePlural
									value="corgi.petition.details.updates.fetched_more"
									count={updates.length - lastEvent.previousItemsCount}
									replacements={{ count: updates.length - lastEvent.previousItemsCount }}
								/>
							</VisuallyHidden>
						)}
						<Update update={update} ref={getListItemRef(index)} />
					</Box>
				))}
			</Box>
			{loadingMore && (
				<>
					<VisuallyHidden role="alert">
						<Translate value="corgi.petition.details.updates.fetching_more" />
					</VisuallyHidden>
					{/* 44 is the height of the button */}
					<Loader size={44} mx="auto" ref={loadingRef} />
				</>
			)}
			{fetchMore && !loadingMore && (
				<LoadMoreButton
					onClick={() => {
						recordListEvent({ type: 'loadMore', itemsCount: updates.length });
						fetchMore();
					}}
					ref={loadMoreRef}
					data-qa="petition-updates-load-more"
				>
					<Translate value="fe.components.updates_feed.view_more_updates_button" />
				</LoadMoreButton>
			)}
		</div>
	);
}

export function UpdatesContainer(): JSX.Element | null {
	const {
		data: { refs },
	} = useScrollToSection();

	return (
		<Box id={SECTION_ID_MAPPING.UPDATES} ref={refs[SECTION_ID_MAPPING.UPDATES]}>
			<Heading as="h2" size={['h4', 'h3']} sx={{ fontSize: '24' }} my={18}>
				<Translate value="corgi.petition_gamma.petition_update.update_feed_title" />
			</Heading>
			<NewUpdateCtaContainer />
			<UpdatesContainerInner />
		</Box>
	);
}
