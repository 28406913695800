import type { JSX } from 'react';

import { MediaHitsContextProvider } from '../containers/MediaHitsContainer';
import { PetitionStatsContextProvider } from '../hooks/petitionStats';
import { ScrollContextProvider } from '../hooks/useScrollToSection';

import { DetailsStateWrapper } from './DetailsStateWrapper';

type Props = Readonly<{
	slug: string;
}>;

/**
 * This component wraps the details page with context providers that aren't part of the `pageContext`. It's likely that
 * these contexts could be refactored to be part of `pageContext/pageState` or `pageContext/pageData`, but they were
 * originally written as their own contexts.
 *
 * The `pageContext` provider is rendered one level higher by `CommonPetitionDetails`.
 */
export function DetailsWithContext({ slug }: Props): JSX.Element {
	return (
		<PetitionStatsContextProvider slug={slug}>
			<MediaHitsContextProvider>
				<ScrollContextProvider>
					<DetailsStateWrapper />
				</ScrollContextProvider>
			</MediaHitsContextProvider>
		</PetitionStatsContextProvider>
	);
}
