import { forwardRef } from 'react';
import type { JSX } from 'react';

import upperFirst from 'lodash/upperFirst';

import { LocalizeDate, useI18n } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { Html } from '@change-corgi/design-system/html';
import { iconVictory } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

type Props = {
	createdAt: string;
	description: string;
};

export const VictoryUpdate = forwardRef<HTMLDivElement, Props>(function VictoryUpdate(
	{ createdAt, description }: Props,
	ref,
): JSX.Element {
	const { translate } = useI18n();
	const victoryHeading = upperFirst(translate('fe.pages.petition_show.status.victory'));

	return (
		<Box
			variant="bordered"
			sx={{
				borderColor: 'neutral-grey100',
				overflow: 'hidden',
				borderWidth: [0, 1],
			}}
			p={16}
			backgroundColor="primary-white"
			ref={ref}
			data-qa="petition-update-victory"
		>
			<Text size="large" sx={{ textAlign: 'center' }}>
				<Icon icon={iconVictory} color="primary-changeRed" size="xxxl" my={16} mx="auto" sx={{ display: 'block' }} />
				<Heading as="div" size="h2" mt={0} mb={16}>
					{victoryHeading}
				</Heading>
			</Text>
			<Text as="div">
				<LocalizeDate date={createdAt} type="date" format="medium" />
				{' — '}
			</Text>
			<Html html={description} breakWord />
		</Box>
	);
});
