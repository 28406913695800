import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaDeleteMediaHit,
	type GammaDeleteMediaHitMutation,
	type GammaDeleteMediaHitMutationVariables,
} from './index.graphql';

export async function deleteMediaHit(
	mediaId: string,
	utilityContext: UtilityContext,
): Promise<GammaDeleteMediaHitMutation | null | undefined> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { data } = await fetch<GammaDeleteMediaHitMutation, GammaDeleteMediaHitMutationVariables>({
		query: GammaDeleteMediaHit,
		variables: {
			input: {
				commentId: mediaId,
			},
		},
		rejectOnError: false,
	});
	return data;
}
