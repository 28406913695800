import { useMemo } from 'react';

import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import { usePrimaryDecisionMakerConnection } from '../../../decisionMakers/hooks/usePrimaryDecisionMakerConnection';

type Result = ModelHookResult<{
	isShown: boolean;
	count: number;
	first: { displayName: string };
	dms: DecisionMaker[];
}>;

export const useDecisionMakers = (): Result => {
	const { data: decisionMakersConnection } = usePrimaryDecisionMakerConnection();

	const decisionMakers = useMemo(() => {
		return {
			data: {
				isShown: decisionMakersConnection.totalCount > 0,
				count: decisionMakersConnection.totalCount,
				first:
					decisionMakersConnection.totalCount > 0 ? decisionMakersConnection.decisionMakers[0] : { displayName: '' },
				dms: decisionMakersConnection.totalCount > 0 ? decisionMakersConnection.decisionMakers : [],
			},
		};
	}, [decisionMakersConnection]);

	return decisionMakers;
};
