import { type JSX, useRef } from 'react';

import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';

import {
	usePetitionDetailsPageData,
	usePetitionDetailsPageUserInfo,
} from 'src/app/pages/petitionGamma/details/pageContext';
import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import {
	AfterDescriptionWaypoint,
	AfterMobileActionButtonWaypoint,
} from 'src/app/pages/petitionGamma/details/shared/waypoints';
import { MembershipAwareness } from 'src/app/shared/components/membershipAwareness';

import { PetitionStarter } from '../components/PetitionStarter';
import { useStickyBehavior } from '../hooks/mainContainer/useStickyBehavior';

import { ContentSummary } from './contentSummary';
import { DecisionMakersMainWrapper } from './decisionMakers/DecisionMakersMainWrapper';
import { DescriptionContainer } from './description';
import { HeadingContainerMobile } from './HeadingMobile';
import { MediaHitsContainer } from './MediaHitsContainer';
import { MobileActionButtonContainer } from './mobileActionButton';
import { PetitionTimestamp } from './PetitionTimestamp';
import { RecentSignersViewportNormal, RecentSignersViewportSmall } from './recentSigners';
import { ReportAbuseContainer } from './ReportAbuse';
import { SapCtaContainer } from './SapCta';
import { ScrollTrackingWaypointContainer } from './ScrollTrackingWaypoint';
import { SharePetition } from './share/SharePetition';
import { SideContainer } from './side';
import { SignatureStatsMobileContainer } from './stats/signatureStats';
import { TagBreadcrumbs } from './TagBreadcrumbs';
import { TheSupporters } from './TheSupporters';
import { UpdatesContainer } from './updatesAndComments/Updates';

/*
Using CSS grid for better keyboard/swipe navigation
╔═══╦═══╗
║ 1 ║   ║
╠═══╣ 2 ║
║ 3 ║   ║
╚═══╩═══╝
*/

export function MainContainer(): JSX.Element {
	const {
		data: {
			petition: { id: petitionId, slug },
		},
	} = usePetitionDetailsPageData();
	const { signed } = usePetitionDetailsPageUserInfo();

	const signatureBlockRef = useRef<HTMLElement>(null);
	const stickyClasses = useStickyBehavior(signatureBlockRef);

	return (
		<Box
			mb={64}
			sx={{
				gridTemplateColumns: '66.66667% 33.33333%',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&': {
					// necessary to override ms-grid above
					display: ['block', 'grid'],
				},
			}}
		>
			<Box sx={{ gridColumn: '1 / 1' }}>
				<HeadingContainerMobile />

				<Box px={[16, 0]}>
					<ResponsiveBox viewportSize="normal">
						<RecentSignersViewportNormal />
						<ContentSummary />
						<DescriptionContainer />
						<ReportAbuseContainer />
						<PetitionStarter />
					</ResponsiveBox>

					<ResponsiveBox viewportSize="small">
						<MobileActionButtonContainer sticky={false} />
						<AfterMobileActionButtonWaypoint />
						<SignatureStatsMobileContainer />
						<RecentSignersViewportSmall />
						<ContentSummary />
						<DescriptionContainer />
						<ReportAbuseContainer />
						<PetitionStarter />
					</ResponsiveBox>

					<ScrollTrackingWaypointContainer name="scroll_past_body" />
					<AfterDescriptionWaypoint />
				</Box>
			</Box>

			<Box sx={{ gridColumn: '2 / 2', gridRow: '1 / span 2' }} pl={[0, 40]}>
				<ResponsiveBox viewportSize="small">
					<MobileActionButtonContainer sticky />
				</ResponsiveBox>
				<ResponsiveBox viewportSize="normal" className={stickyClasses}>
					<SideContainer containerRef={signatureBlockRef} />
				</ResponsiveBox>
			</Box>

			<Box sx={{ gridColumn: '1 / 1', gridRow: '2 / 2' }} px={[16, 0]}>
				<SapCtaContainer />

				<DecisionMakersMainWrapper />

				<TheSupporters />
				<Box my={24}>
					<MembershipAwareness sourceLocation="petition_details" petitionId={petitionId} slug={slug} signed={signed} />
				</Box>
				<ScrollTrackingWaypointContainer name="scroll_updates_section" />

				<Separator role="presentation" my={24} />

				<UpdatesContainer />

				<MediaHitsContainer />

				<Separator role="presentation" my={24} />
				<SharePetition />

				<Separator role="presentation" my={24} />
				<PetitionTimestamp />

				<Separator role="presentation" my={24} />
				<TagBreadcrumbs />
			</Box>
		</Box>
	);
}
