import { Translate } from '@change-corgi/core/react/i18n';
import { Heading } from '@change-corgi/design-system/typography';

export const TheSupportersTitle: React.FC<EmptyObject> = () => {
	return (
		<Heading as="h2" sx={{ fontSize: '24' }} mt={24}>
			<Translate value="corgi.petition_gamma.supporters.title" />
		</Heading>
	);
};
