import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { getPetitionUpdates } from 'src/app/pages/petitionGamma/details/shared/api';
import type { PetitionUpdates } from 'src/app/pages/petitionGamma/details/shared/types';

export type Options = Readonly<{
	/**
	 * number of non-milestone updates to aim for
	 */
	nonMilestoneCount: number;
	/**
	 * number of updates to retrieve for each request
	 */
	pageSize: number;
	/**
	 * cursor to start searching at
	 */
	cursor?: string;
}>;

async function getPetitionUpdatesPagesUntilCountInternal(
	id: string,
	{ nonMilestoneCount, cursor, pageSize }: Options,
	utilityContext: UtilityContext,
	currentCount: number,
): Promise<readonly PetitionUpdates[]> {
	const page = await getPetitionUpdates(id, { count: pageSize, cursor }, utilityContext);
	if (!page) {
		return [];
	}
	const updatesCount = currentCount + page.updates.filter(({ type }) => type !== 'milestone').length;
	if (updatesCount >= nonMilestoneCount || !page.endCursor) {
		return [page];
	}
	return [
		page,
		...(await getPetitionUpdatesPagesUntilCountInternal(
			id,
			{ nonMilestoneCount, pageSize, cursor: page.endCursor },
			utilityContext,
			updatesCount,
		)),
	];
}

export async function getPetitionUpdatesPagesUntilCount(
	id: string,
	options: Options,
	utilityContext: UtilityContext,
): Promise<readonly PetitionUpdates[]> {
	return getPetitionUpdatesPagesUntilCountInternal(id, options, utilityContext, 0);
}
