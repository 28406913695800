import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { usePetitionDetails } from '../../../hooks/petitionDetails';

import { SignatureStatsActiveContainer } from './SignatureStatsActiveContainer';
import { SignatureStatsClosedContainer } from './SignatureStatsClosed';

function SignatureStatsContainerInner() {
	const { status } = usePetitionDetails();

	if (status === 'CLOSED') {
		return <SignatureStatsClosedContainer />;
	}

	if (status === 'VICTORY') {
		return null;
	}

	return <SignatureStatsActiveContainer />;
}

export function SignatureStatsMobileContainer(): JSX.Element {
	return (
		<Box my={16} data-qa="signature-stats-section">
			<SignatureStatsContainerInner />
		</Box>
	);
}
