import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

type Result = ModelHookResult<{
	isShown: boolean;
	count: number;
}>;

export const useMediaHits = (): Result => {
	const {
		data: {
			petition: { petitionMediaHitsConnection },
		},
	} = usePetitionDetailsPageData();

	const mediaHits = useMemo(() => {
		const { totalCount } = petitionMediaHitsConnection;

		return {
			data: {
				isShown: totalCount > 0,
				count: totalCount,
			},
		};
	}, [petitionMediaHitsConnection]);

	return mediaHits;
};
