import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { SECTION_ID_MAPPING, useScrollToSection } from '../../../../hooks/useScrollToSection';
import { ScrollTrackingWaypointContainer } from '../../../ScrollTrackingWaypoint';
import { AddMediaHit } from '../AddMediaHit';
import { MediaHitList } from '../MediaHitList';

import { useMediaHits } from './hooks/useMediaHits';

export function MediaHitsWrapper(): JSX.Element | null {
	const {
		data: { showAdd, showSection, showList, trackingData },
	} = useMediaHits();

	const {
		data: { refs },
	} = useScrollToSection();

	if (!showSection) {
		return null;
	}

	return (
		<>
			<Separator role="presentation" my={24} />
			<Box
				id={SECTION_ID_MAPPING.MEDIA_MENTIONS}
				ref={refs[SECTION_ID_MAPPING.MEDIA_MENTIONS]}
				data-testid="media-hits-wrapper"
			>
				<Heading as="h2" size={['h3']} mb={16}>
					<Translate value="corgi.petition_gamma.media_mentions.title" />
				</Heading>
				<ScrollTrackingWaypointContainer name="campaign_media_hits_view" trackingData={trackingData} />

				{showAdd ? <AddMediaHit /> : null}
				{showList ? <MediaHitList /> : null}
			</Box>
		</>
	);
}
