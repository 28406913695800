import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaPetitionPagePetitionInfo,
	type GammaPetitionPagePetitionInfoQuery,
	type GammaPetitionPagePetitionInfoQueryVariables,
} from './petition.graphql';

export type PartialPetition = NonNullable<GammaPetitionPagePetitionInfoQuery['petition']>;

export async function getPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition | undefined> {
	const { data } = await fetch<GammaPetitionPagePetitionInfoQuery, GammaPetitionPagePetitionInfoQueryVariables>({
		query: GammaPetitionPagePetitionInfo,
		variables: { slugOrId },
		rejectOnError: true,
	});
	return data?.petition || undefined;
}
