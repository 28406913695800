import type { PetitionUpdatesList } from 'src/app/pages/petitionGamma/details/shared/types';

export function extractDisplayedUpdates(
	updates: PetitionUpdatesList,
	signatureDisplayCount: number,
): PetitionUpdatesList {
	let filtered = updates;

	filtered = filtered
		// remove milestone with a signature count > that what is displayed on the page
		.filter((update) => update.type !== 'milestone' || update.signatureCount <= signatureDisplayCount);

	// remove consecutive milestones
	filtered = filtered.filter(
		(update, index) => update.type !== 'milestone' || !index || filtered[index - 1].type !== 'milestone',
	);

	const firstNonMilestone = filtered.findIndex(({ type }) => type !== 'milestone');
	if (firstNonMilestone > 0) {
		// if there are non-milestone updates, hide all milestone after (ie before in the list) the first update
		filtered = filtered.slice(firstNonMilestone);
	}

	return filtered;
}
