import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { useSupporterVideos } from '../../../../hooks/supporterVoices/supporterVideos';

type Result = ModelHookResult<{
	isShown: boolean;
	count: number;
}>;

export const useSupporterVoices = (): Result => {
	const {
		data: {
			supporterVoicesData,
			petition: {
				commentsConnection: { totalCount: commentsCount },
			},
		},
	} = usePetitionDetailsPageData();

	const {
		data: { supporterVideos },
	} = useSupporterVideos({ supporterVoicesData });

	const totalCount = Number(commentsCount) + supporterVideos.length;

	const supporterVoices = useMemo(() => {
		return {
			data: {
				isShown: totalCount >= 3,
				count: totalCount,
			},
		};
	}, [totalCount]);

	return supporterVoices;
};
