import { useEffect } from 'react';

import { useSignaturesDistributionContext } from '../../../shared/provider';
import { pickZoomLevel } from '../utils/pickZoomLevel';

export function useZoomLevel() {
	const {
		data: { clusterSize, zoomLevel, signaturesClusters },
		actions: { setZoomLevel },
	} = useSignaturesDistributionContext();

	useEffect(() => {
		if (clusterSize && signaturesClusters && signaturesClusters[0] && !zoomLevel) {
			const { center } = signaturesClusters[0];
			setZoomLevel(pickZoomLevel(clusterSize, center));
		}
	}, [clusterSize, zoomLevel, setZoomLevel, signaturesClusters]);
}
