import { usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';

import { SECTION_ID_MAPPING, useScrollToSection } from '../../hooks/useScrollToSection';

import { DecisionMakerCardsContainer } from './DecisionMakerCardsContainer';
import { DecisionMakersContainer } from './DecisionMakers';

export function DecisionMakersMainWrapper() {
	const { dmCardConfig } = usePetitionDetailsPageFcm();
	const {
		data: { refs },
	} = useScrollToSection();

	return (
		<div id={SECTION_ID_MAPPING.DECISION_MAKERS} ref={refs[SECTION_ID_MAPPING.DECISION_MAKERS]}>
			{dmCardConfig?.enabled ? <DecisionMakerCardsContainer /> : <DecisionMakersContainer />}
		</div>
	);
}
