import { useMemo } from 'react';

import type { CreationPetitionUpdate } from 'src/app/pages/petitionGamma/details/shared/types';

import { usePetitionDetails } from '../petitionDetails';

export function useCreationUpdate(): CreationPetitionUpdate {
	const details = usePetitionDetails();

	return useMemo(() => {
		const { createdAt, organization, user } = details;
		const displayName = organization ? organization.name : user.displayName;
		return {
			id: '__creation_fake_id__',
			type: 'creation' as const,
			createdAt,
			displayName,
		} as CreationPetitionUpdate;
	}, [details]);
}
