import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PetitionUpdates, PetitionUpdatesList } from 'src/app/pages/petitionGamma/details/shared/types';

import type { Options } from './getPetitionUpdatesPagesUntilCount';
import { getPetitionUpdatesPagesUntilCount } from './getPetitionUpdatesPagesUntilCount';

/**
 * Return petition updates with at least "nonMilestoneCount" non-milestone updates (unless all updates were retrieved)
 *
 * This will call the API recursively until the list contains "nonMilestoneCount" non-milestone updates,
 * or the end of the list was reached
 */
export async function getPetitionUpdates(
	id: string,
	options: Options,
	utilityContext: UtilityContext,
): Promise<PetitionUpdates | undefined> {
	const pages = await getPetitionUpdatesPagesUntilCount(id, options, utilityContext);
	if (!pages.length) {
		return undefined;
	}
	const lastPage = pages[pages.length - 1];
	return {
		updates: ([] as PetitionUpdatesList).concat(...pages.map(({ updates }) => updates)),
		endCursor: lastPage.endCursor,
	};
}
