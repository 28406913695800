import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PetitionUpdates } from 'src/app/pages/petitionGamma/details/shared/types';

import { getPetitionUpdates as getPetitionUpdatesBase } from './api';
import type { PetitionUpdatesData, QueryState } from './types';

type Options = Readonly<{
	nonMilestoneCount: number;
	pageSize: number;
	cursor?: string;
}>;

export async function getPetitionUpdatesWithNormalizedError(
	id: string,
	options: Options,
	utilityContext: UtilityContext,
): Promise<PetitionUpdates | undefined> {
	try {
		const updates = await getPetitionUpdatesBase(id, options, utilityContext);
		return updates;
	} catch (e) {
		throw new Error();
	}
}

export async function getPetitionUpdatesOrThrow(
	id: string,
	options: Options,
	utilityContext: UtilityContext,
): Promise<PetitionUpdates> {
	const updates = await getPetitionUpdatesWithNormalizedError(id, options, utilityContext);
	if (!updates) {
		throw new Error();
	}
	return updates;
}

const UPDATES_MIN_PAGESIZE = 10;

export async function getPetitionUpdates(
	id: string,
	queryState: QueryState,
	utilityContext: UtilityContext,
): Promise<PetitionUpdatesData> {
	const { cursor, updates, count } = queryState;
	// we were already at the end, let's just return the current state
	if (cursor === null) {
		return {
			updates,
			endCursor: null,
			displayedCount: count,
		};
	}

	// we are always retrieving "count" non-milestone updates,
	// just in case all milestones end up being filtered out
	const nonMilestoneCount = count - updates.filter(({ type }) => type !== 'milestone').length;

	if (nonMilestoneCount <= 0 && typeof cursor !== 'undefined') {
		// we already have what's necessary in memory
		return {
			updates,
			endCursor: cursor,
			displayedCount: count,
		};
	}

	const page = await getPetitionUpdatesOrThrow(
		id,
		{
			nonMilestoneCount,
			// we're getting bigger pages than the needed count,
			// to take potential milestones into account and to avoid
			// multiple requests as much as possible
			pageSize: Math.max(UPDATES_MIN_PAGESIZE, nonMilestoneCount * 2),
			cursor,
		},
		utilityContext,
	);

	// we are generating a full list by concatenating the already retrieved list
	// with the updates from this new page
	return {
		updates: [...updates, ...page.updates],
		endCursor: page.endCursor,
		displayedCount: count,
	};
}
