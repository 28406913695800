import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useMitaPhoto } from '../../hooks/mita';
import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useShareHeadline } from '../../hooks/shareHeadline';

const DEFAULT_PHOTO = { url: 'https://static.change.org/images/default-petition.gif', width: 1120, height: 630 };

export function MetaOGContainer(): JSX.Element | null {
	const { status, signatureState, ask, photo } = usePetitionDetails();
	const { resolveUrl } = useUtilityContext();
	const mitaPhoto = useMitaPhoto();
	const { signatureCount } = signatureState;
	const { translate, translatePlural } = useI18n();
	const shareHeadline = useShareHeadline();

	const ogTitleContent =
		status === 'VICTORY'
			? translatePlural('fe.facebook.share.victory', signatureCount.displayed, {
					signature_count: signatureCount.displayedLocalized,
				})
			: shareHeadline?.title || translate('fe.facebook.share.sign_petition');

	const ogDescriptionContent = status === 'VICTORY' ? ask : shareHeadline?.description || ask;

	const metaPhoto = mitaPhoto || photo?.petitionLarge || DEFAULT_PHOTO;

	return (
		<Helmet>
			<meta property="og:title" content={ogTitleContent} />
			<meta property="og:description" content={ogDescriptionContent} />
			<meta property="og:image" content={resolveUrl(metaPhoto.url)} />
			<meta property="og:image:secure_url" content={resolveUrl(metaPhoto.url)} />
			{/* https://github.com/jsx-eslint/eslint-plugin-react/issues/3607 */}
			<meta property="og:image:width" content={`${metaPhoto.width}`} />
			{/* https://github.com/jsx-eslint/eslint-plugin-react/issues/3607 */}
			<meta property="og:image:height" content={`${metaPhoto.height}`} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="twitter:image" content={resolveUrl(metaPhoto.url)} />
			<meta property="twitter:image:alt" content={ogTitleContent} />
		</Helmet>
	);
}
