import { useCallback, useState } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/core/react/router';
import { Separator } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconCheck, iconNavigateBefore } from '@change-corgi/design-system/icons';
import { Container, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import { useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useDmFeedUrls } from '../hooks/decisionMakers/dmFeedUrls';

export function DmFeedBannerContainer(): JSX.Element | null {
	// TODO: This logic is very permissive—any logged-in user with the `r` query param set
	// to a URL will see this banner, and the banner will link to that URL. We could
	// probably make this logic a bit more airtight.
	// https://change.atlassian.net/browse/FE-572
	const loginState = useLoginStateAsync();
	const { feedUrl, responseRequestUrl } = useDmFeedUrls();
	const [hasSentResponseRequest, setHasSentResponseRequest] = useState(false);
	const handleResponseRequestClick = useCallback(() => setHasSentResponseRequest(true), [setHasSentResponseRequest]);

	// This could perform a layout shift but it's a very unusual edge case
	if (!isLoaded(loginState) || loginState.value === 'GUEST' || !feedUrl) return null;

	return (
		<ResponsiveBox
			viewportSize="normal"
			sx={{ alignItems: 'center', backgroundColor: 'primary-changeRed', color: 'typography-lightPrimary' }}
		>
			<Container sx={{ height: 54, display: 'flex', alignItems: 'center' }} mx={[0, 'auto']}>
				<Link
					to={feedUrl}
					sx={{
						...linkResetStyles,
						display: 'flex',
						alignItems: 'center',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': { textDecoration: 'underline' },
					}}
				>
					<Icon size="xxl" icon={iconNavigateBefore} mr={8} />
					<Translate value="fe.components.petition_decision_maker_banner.back_link" />
				</Link>
				{responseRequestUrl && (
					<Flex sx={{ alignItems: 'center' }} ml={32} data-testid="dm-response-request">
						{hasSentResponseRequest ? (
							<>
								<Icon icon={iconCheck} mr={4} />
								<Translate value="fe.components.petition_decision_maker_response_request.acknowledgement" />
							</>
						) : (
							<Link
								to={responseRequestUrl}
								sx={{
									...linkResetStyles,
									// eslint-disable-next-line @typescript-eslint/naming-convention
									'&:hover': { textDecoration: 'underline' },
								}}
								onClick={handleResponseRequestClick}
							>
								<Translate value="fe.components.petition_decision_maker_response_request.request_to_respond" />
							</Link>
						)}
					</Flex>
				)}
			</Container>
			<Separator role="presentation" my={0} />
		</ResponsiveBox>
	);
}
