import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaSignaturesOfPetition,
	type GammaSignaturesOfPetitionQuery,
	type GammaSignaturesOfPetitionQueryVariables,
} from './useFetchSignatures.graphql';

export const fetchSignatures = async ({
	petitionId,
	utilityContext: {
		gql: { fetch },
	},
}: {
	petitionId: string;
	utilityContext: UtilityContext;
}) => {
	try {
		const { data } = await fetch<GammaSignaturesOfPetitionQuery, GammaSignaturesOfPetitionQueryVariables>({
			query: GammaSignaturesOfPetition,
			variables: {
				petitionId,
			},
			rejectOnError: true,
			batched: false,
		});

		if (
			!data ||
			!data.getSignaturesOfPetition ||
			!data.getSignaturesOfPetition.items ||
			data.getSignaturesOfPetition.items.length === 0
		) {
			throw new Error();
		}

		const { items: signaturesWithPosition } = data.getSignaturesOfPetition;

		return { distribution: signaturesWithPosition };
	} catch {
		return null;
	}
};
