import { useTracking } from '@change-corgi/core/react/tracking';
import { openWindow } from '@change-corgi/core/window';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { craftSupporterEmail } from 'src/app/shared/utils/decisionMaker';

type Result = ModelHookResult<
	{ emailHref: string },
	{
		handleEmailDmClick: () => void;
	}
>;

export function useEmailDm({ dmEmail, dmName }: { dmEmail: string; dmName: string }): Result {
	const track = useTracking();

	const {
		data: {
			petition: {
				slug,
				ask,
				signatureState: {
					signatureCount: { displayedLocalized: signatureCount },
				},
			},
			emailDm,
		},
	} = usePetitionDetailsPageData();

	const emailHref = craftSupporterEmail({
		dmEmail,
		dmName,
		ask,
		slug,
		signatureCount,
		emailDm,
	});

	const handleEmailDmClick = () => {
		void track('petition_page_email_dm_click');
		if (emailHref) openWindow(emailHref);
	};

	return {
		data: { emailHref },
		actions: {
			handleEmailDmClick,
		},
	};
}
