import type { RefObject } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { getWindowSafe } from '@change-corgi/core/window';

import { usePetitionDetailsPageUserData } from 'src/app/pages/petitionGamma/details/pageContext';

const MARGIN_THRESHOLD = 60;

/**
 * This powers the sticky signature block on large breakpoints. The signature block is only made sticky if it fits
 * within the screen. It also accounts for lazy-loaded content inside the signature block, which can change its height.
 * Even after all content is loaded, the block's height may still change. This is why a ResizeObserver is used.
 */
export const useStickyBehavior = (signatureBlockRef: RefObject<HTMLElement | null>): string | undefined => {
	const [canBeSticky, setCanBeSticky] = useState(false);

	const {
		data: { stickySignatureBlockExperimentEnabled },
	} = usePetitionDetailsPageUserData();

	const windowObject = getWindowSafe();

	const isResizeObserverSupported = useMemo(
		() => typeof windowObject !== 'undefined' && 'ResizeObserver' in windowObject,
		[windowObject],
	);

	useEffect(() => {
		if (
			!isResizeObserverSupported ||
			!signatureBlockRef.current ||
			!stickySignatureBlockExperimentEnabled ||
			!windowObject // windowObject should be defined at this point, adding it only to prevent type issues
		) {
			return undefined;
		}
		setCanBeSticky(signatureBlockRef.current.offsetHeight + MARGIN_THRESHOLD < windowObject.innerHeight);

		const observer = new windowObject.ResizeObserver(([entry]: ResizeObserverEntry[]) => {
			setCanBeSticky(entry.contentRect.height + MARGIN_THRESHOLD < windowObject.innerHeight);
		});

		observer.observe(signatureBlockRef.current);

		return () => {
			observer.disconnect();
		};
	}, [signatureBlockRef, isResizeObserverSupported, stickySignatureBlockExperimentEnabled, windowObject]);

	return canBeSticky ? 'sticky top-4' : '';
};
