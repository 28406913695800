import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

export function useScrollTracking(): (name: string, trackingData?: TrackingProperties) => void {
	const track = useTracking();

	return useCallback(
		(name: string, trackingData) => {
			void track(name, trackingData);
		},
		[track],
	);
}
