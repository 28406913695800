import type { JSX, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import { useSignaturesDistributionContextValue } from './context';
import type { SignaturesDistributionContextValueType } from './context';
import type { MapConfiguration } from './types';

const SignaturesDistributionContext = createContext<SignaturesDistributionContextValueType>(
	null as unknown as SignaturesDistributionContextValueType,
);

export function SignaturesDistributionContextProvider({
	configuration,
	children,
}: PropsWithChildren<{ configuration: MapConfiguration }>): JSX.Element {
	const value = useSignaturesDistributionContextValue(configuration);

	return <SignaturesDistributionContext.Provider value={value}>{children}</SignaturesDistributionContext.Provider>;
}

export const useSignaturesDistributionContext = () => {
	const value = useContext(SignaturesDistributionContext);

	return value;
};
