import { useMemo } from 'react';

import type { PetitionUpdatesList } from 'src/app/pages/petitionGamma/details/shared/types';
import { isLoaded } from 'src/app/shared/utils/async';

import type { PetitionUpdatesQueryState, QueryState } from './types';

const UPDATES_PER_PAGE = 10;

export function useFetchMore(
	updatesState: PetitionUpdatesQueryState,
	filteredUpdates: PetitionUpdatesList,
	setQueryState: (queryState: QueryState) => void,
): (() => void) | undefined {
	return useMemo(() => {
		if (!isLoaded(updatesState)) {
			return undefined;
		}

		const { updates, endCursor, displayedCount } = updatesState;

		if (!updatesState.endCursor && displayedCount >= filteredUpdates.length) {
			return undefined;
		}

		return () => {
			setQueryState({
				updates,
				cursor: endCursor,
				count: displayedCount + UPDATES_PER_PAGE,
			});
		};
	}, [updatesState, filteredUpdates, setQueryState]);
}
