import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetails } from '../petitionDetails';
import { useUserInfo } from '../userInfo';

import { useTrackingPetitionStatus } from './shared/petitionStatus';

type Result = {
	trackClick: () => void;
};

export function useSapCtaTracking(): Result {
	const { id, movements } = usePetitionDetails();
	const track = useTracking();

	const { signed } = useUserInfo();
	const status = useTrackingPetitionStatus();

	const movementId = movements.nodes[0]?.id;

	return useMemo(
		() => ({
			trackClick: () => {
				void track('petition_show_sap_cta_clicked', {
					petition_id: id,
					signed,
					petition_status: status,
					movement_id: movementId,
				});
			},
		}),
		[track, id, movementId, signed, status],
	);
}
