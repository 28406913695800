import React from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { SupporterVoicesComments } from '../supporterVoicesComments';

import { SupporterVoicesVideos } from './components/SupporterVoicesVideos';
import { useSupporterVoicesSection } from './hooks/useSupporterVoicesSection';

export const SupporterVoices: React.FC = () => {
	const {
		data: { showSupporterVoicesSection },
	} = useSupporterVoicesSection();

	if (!showSupporterVoicesSection) {
		return <Box sx={{ display: 'none' }} data-testid="petition-page-supporters-voices-hidden" />;
	}

	return (
		<Box data-testid="petition-page-supporters-voices">
			<SupporterVoicesComments />

			<SupporterVoicesVideos />
		</Box>
	);
};
