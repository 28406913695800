import { useQuery } from '@change-corgi/core/react/async';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { usePetitionDetails } from '../petitionDetails';

import { getPetitionUpdates } from './getPetitionUpdates';
import type { PetitionUpdatesQueryState, QueryState } from './types';

export function usePetitionUpdatesQuery(queryState: QueryState): PetitionUpdatesQueryState {
	const { id } = usePetitionDetails();

	const utilityContext = useUtilityContext();

	return useQuery(async () => getPetitionUpdates(id, queryState, utilityContext), [id, utilityContext, queryState]);
}
