import { useMemo } from 'react';

import type { PetitionUpdatesList } from 'src/app/pages/petitionGamma/details/shared/types';
import type { State } from 'src/app/shared/utils/async';
import { isLoaded } from 'src/app/shared/utils/async';

import { useCreationUpdate } from './creationUpdate';
import { useDisplayedUpdates } from './displayedUpdates';
import { useFetchMore } from './fetchMore';
import { usePetitionUpdatesQuery } from './petitionUpdatesQuery';
import { useQueryState } from './queryState';
import { useVictoryUpdate } from './victoryUpdate';

type PetitionUpdatesState = State<{ updates: PetitionUpdatesList; loadingMore: boolean; fetchMore?: () => void }>;

function isLoadingMore(queryStateCursor: string | null | undefined, updatesStateEndCursor: string | null) {
	return queryStateCursor !== null && queryStateCursor === updatesStateEndCursor;
}

export function usePetitionUpdates(): PetitionUpdatesState {
	const creationUpdate = useCreationUpdate();
	const victoryUpdate = useVictoryUpdate();

	// query state is updated by fetchMore to trigger a new query
	const [queryState, setQueryState] = useQueryState();

	// this will query the API based on the query state
	const updatesState = usePetitionUpdatesQuery(queryState);

	// this "hides" updates (milestones) based on business logic
	const filteredUpdates = useDisplayedUpdates(updatesState);

	const fetchMore = useFetchMore(updatesState, filteredUpdates, setQueryState);

	return useMemo(() => {
		if (!isLoaded(updatesState)) {
			return updatesState;
		}
		const { displayedCount } = updatesState;
		const truncatedUpdates = filteredUpdates.slice(0, displayedCount);
		const truncatedUpdatesWithVictoryUpdate = [...(victoryUpdate ? [victoryUpdate] : []), ...truncatedUpdates];
		const updates = fetchMore
			? truncatedUpdatesWithVictoryUpdate
			: [
					...truncatedUpdatesWithVictoryUpdate,
					// last page, let's add the creation update
					creationUpdate,
				];
		return {
			status: 'loaded',
			updates,
			loadingMore: isLoadingMore(queryState.cursor, updatesState.endCursor),
			fetchMore,
		};
	}, [updatesState, queryState, filteredUpdates, fetchMore, creationUpdate, victoryUpdate]);
}
