import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import type { PetitionVictoryUpdate } from 'src/app/pages/petitionGamma/details/shared/types';

import { usePetitionDetails } from '../petitionDetails';

// The victory date is a truncated date string (e.g. 2021-01-01) in the database
// which JS doesn't handle very well—in a time zone earlier than GMT, this will be
// parsed to e.g. 2021-01-01 00:00:00 GMT, which could be 2019-12-31 in the browser/server's
// time zone. This uses the date parts to create an ISO date string that will be
// formatted in a way that matches the truncated string from the database.
function fixVictoryDateTimeZone(victoryDate: string) {
	const victoryDateStringParts = victoryDate.split('-');
	const [year, month, day] = victoryDateStringParts.map((strPart) => Number(strPart));
	// Subtract 1 from month because months are zero-indexed
	return new Date(
		// same year as date from db
		year,
		// subtract 1 from month because months are 0-indexed
		month - 1,
		// same day as date from db
		day,
	).toISOString();
}

export function useVictoryUpdate(): PetitionVictoryUpdate | undefined {
	const { id, status, victoryDate, victoryDescription } = usePetitionDetails();
	const { localizeDate } = useI18n();

	return useMemo(() => {
		// We should only show the victory update if the petition is in a victory status and
		// has the date and description fields required to render the update.
		// Some petitions can have their victories confirmed and later be moved to a non-victory
		// state—in this case, the victory date and description can remain on the petition,
		// but we'll want to hide the update to be consistent with the petition status header.
		if (status !== 'VICTORY' || !victoryDate || !victoryDescription) return undefined;

		return {
			id: `__petition_${id}_victory_update_fake_id__`,
			type: 'victory' as const,
			createdAt: fixVictoryDateTimeZone(victoryDate),
			createdAtLocalized: localizeDate(victoryDate, 'date', 'medium'),
			description: victoryDescription,
		};
	}, [status, victoryDate, victoryDescription, id, localizeDate]);
}
