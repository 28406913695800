import { useState } from 'react';

import type { QueryState } from './types';

const INITIAL_UPDATES_COUNT = 2;

export function useQueryState(): [QueryState, (s: QueryState) => void] {
	return useState<QueryState>({
		updates: [],
		cursor: undefined,
		count: INITIAL_UPDATES_COUNT,
	});
}
