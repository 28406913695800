import type { EmptyObject } from '@change-corgi/core/types';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';

import { SECTION_ID_MAPPING, useScrollToSection } from '../../hooks/useScrollToSection';
import { SignaturesDistributionContainer } from '../SignaturesDistributionContainer';
import { SupporterVoices } from '../supportersVoices';

import { TheSupportersTitle } from './components/TheSupportersTitle';
import { TheSupportersContextProvider, useTheSupportersContext } from './shared/context';

const TheSupportersInner: React.FC<EmptyObject> = () => {
	const {
		data: { hasContent },
	} = useTheSupportersContext();

	const {
		data: { refs },
	} = useScrollToSection();

	if (!hasContent) {
		return <Box sx={{ display: 'none' }} data-testid="petition-page-supporters-section-hidden" />;
	}

	return (
		<div id={SECTION_ID_MAPPING.SUPPORTER_VOICES} ref={refs[SECTION_ID_MAPPING.SUPPORTER_VOICES]}>
			<Separator role="presentation" my={24} />
			<Box mb={[24, 64]}>
				<TheSupportersTitle />
				<SupporterVoices />
				<SignaturesDistributionContainer />
			</Box>
		</div>
	);
};

export const TheSupporters: React.FC<EmptyObject> = () => {
	return (
		<TheSupportersContextProvider>
			<TheSupportersInner />
		</TheSupportersContextProvider>
	);
};
