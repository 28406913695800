import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

import { useScrollContextValue } from './useScrollContextValue';

type ScrollContextType = ReturnType<typeof useScrollContextValue> | null;

const ScrollContext = createContext<ScrollContextType>(null);

export const ScrollContextProvider = ({ children }: { children: ReactNode }) => {
	const scrollContextValue = useScrollContextValue();

	return <ScrollContext.Provider value={scrollContextValue}>{children}</ScrollContext.Provider>;
};

export const useScrollToSection = () => {
	const context = useContext(ScrollContext);

	if (!context) {
		throw new Error('useScrollToSection must be used within a ScrollContextProvider');
	}

	return context;
};
