import { TranslatePluralHtml } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { Avatar } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import type { AllDmResponses } from '../../hooks/useDecisionMakers';

import { DmResponseStatus } from './components/DmResponseStatus';
import { EmailDecisionMaker } from './components/EmailDecisionMaker';
import { ReadDmResponse } from './components/ReadDmResponse';
import { useDecisionMakerCard } from './hooks/useDecisionMakerCard';

type DecisionMakerCardProps = {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function DecisionMakerCard({ decisionMaker, allDmResponses }: DecisionMakerCardProps): JSX.Element {
	const photoUrl = decisionMaker.photo?.userMedium.processing ? undefined : decisionMaker.photo?.userMedium.url;
	const {
		actions: { handleDmProfileLinkClick },
	} = useDecisionMakerCard();
	const { targetPetitionAssociationData } = decisionMaker;
	const { constituentCountAmongSigners, constituentCountAmongSignersLocalized } = targetPetitionAssociationData || {};

	return (
		<Box p={16} mb={[0, 16]} sx={{ border: '1px solid #999999', borderRadius: 8 }} data-qa="dm-card">
			<Flex mb={16}>
				<Avatar variant="subtle" data-testid="dm-image" src={photoUrl} mr={10} size={64} alt="" loading="lazy" />
				<Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
					{decisionMaker.publiclyVisible ? (
						<Link
							sx={{ fontWeight: 'bold' }}
							color="primary-black"
							data-testid="dm-link"
							data-qa="dm-link"
							to={`/decision-makers/${decisionMaker.slug || decisionMaker.id}?source_location=petition_details_gamma`}
							onClick={() => handleDmProfileLinkClick(decisionMaker.id)}
						>
							<Text fontWeight="semibold" ellipsis>
								{decisionMaker.displayName}
							</Text>
						</Link>
					) : (
						<Text fontWeight="semibold" ellipsis>
							{decisionMaker.displayName}
						</Text>
					)}

					<Text as="p">{decisionMaker.title}</Text>
					{constituentCountAmongSigners && constituentCountAmongSignersLocalized && (
						<Text>
							<TranslatePluralHtml
								count={constituentCountAmongSigners}
								value="corgi.petition_gamma.decision_makers.constituency"
								replacements={{
									numberOfConstituents: constituentCountAmongSignersLocalized,
									dm: decisionMaker.displayName,
								}}
							/>
						</Text>
					)}
				</Flex>
			</Flex>
			<DmResponseStatus decisionMaker={decisionMaker} allDmResponses={allDmResponses} />
			<EmailDecisionMaker dmEmail={decisionMaker?.email} dmName={decisionMaker.displayName} />
			<ReadDmResponse decisionMaker={decisionMaker} allDmResponses={allDmResponses} />
		</Box>
	);
}
