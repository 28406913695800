import { useCallback, useState } from 'react';
import type { JSX } from 'react';

import { Waypoint } from 'react-waypoint';

import type { TrackingProperties } from '@change-corgi/tracking-properties';

import type { WaypointCallbackArgs } from 'src/app/shared/components/layout/lazy';

import { useScrollTracking } from '../hooks/tracking/scroll';

type Props = Readonly<{
	name: string;
	trackingData?: TrackingProperties;
}>;

export function ScrollTrackingWaypointContainer({ name, trackingData }: Props): JSX.Element {
	const [tracked, setTracked] = useState(false);
	const trackScroll = useScrollTracking();

	const onPositionChange = useCallback(
		({ currentPosition }: WaypointCallbackArgs) => {
			if (tracked || currentPosition === 'below') {
				return;
			}
			trackScroll(name, trackingData);
			setTracked(true);
		},

		// trackingData is not memoized and will trigger recreation
		// of this function if added as a dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[name, tracked, setTracked, trackScroll],
	);

	return <Waypoint onPositionChange={onPositionChange} />;
}
