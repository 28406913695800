import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetails } from '../petitionDetails';

type Result = {
	trackClick: (linkLocation: string) => void;
};

export function usePetitionPageMovementLinkTracking(): Result {
	const { id, movements } = usePetitionDetails();
	const track = useTracking();

	const movementId = movements.nodes[0]?.id;

	return useMemo(
		() => ({
			trackClick: (linkLocation) => {
				void track('petition_page_mvmt_click', {
					petition_id: id,
					link_location: linkLocation,
					movement_id: movementId,
				});
			},
		}),
		[track, id, movementId],
	);
}
