import { usePetitionDetails } from '../petitionDetails';
import { useUserInfo } from '../userInfo';

type Result = {
	data: {
		canEdit: boolean;
		updateHref: string;
	};
};

export function useNewUpdateCta(): Result {
	const { slug } = usePetitionDetails();

	const { canEdit } = useUserInfo();

	const updateHref = `/p/${slug}/u/new`;

	return {
		data: { canEdit, updateHref },
	};
}
