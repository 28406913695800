import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import type { SubEnvironment } from '@change-corgi/config/environments';
import { getFacebookNamespace } from '@change-corgi/config/facebook';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

export function MetaTypeContainer(): JSX.Element | null {
	const { environment } = useUtilityContext();

	const env = environment.getDemoEnvironment() || environment.getEnvironment();
	const namespace = getFacebookNamespace(env as SubEnvironment);

	return <Helmet>{namespace && <meta property="og:type" content={`${namespace}:petition`} />}</Helmet>;
}
