/* eslint-disable max-lines-per-function */
import { useCallback, useMemo } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router';

import { useKnownAvailableParams } from '@change-corgi/core/react/router';
import { useTracking } from '@change-corgi/core/react/tracking';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { useDecisionMakerRecommender } from 'src/app/shared/hooks/decisionMakers';

import { usePetitionDetails } from '../../../../../hooks/petitionDetails';
import type { TipType } from '../../../types';

type DecisionMakerType = 'PERSON' | 'ORG';
type RecommendedDecisionMaker = {
	name: string;
	type: DecisionMakerType;
	title: string | null;
	normalizedDmId: string;
	canonicalPetitionTargetId: string | null;
	readonly photo: {
		readonly id: string;
		readonly petitionMedium: {
			readonly url: string;
			readonly processing: boolean;
			readonly width: number;
			readonly height: number;
		};
	} | null;
};

const MAX_RECOMMENDATIONS = 3;

export function useGetTipActions() {
	const track = useTracking();
	const { pathname, search } = useLocation();
	const { slug } = useKnownAvailableParams<{ slug: string }>();
	const { id, recommendedDecisionMakers } = usePetitionDetails();

	const openDecisionMakerRecommendationModal = useDecisionMakerRecommender();
	const pathnameToPetition = useMemo(() => {
		return pathname.replace(/\/pgamma\//, '/p/');
	}, [pathname]);
	const trackingInfo = useMemo((): TrackingProperties => {
		return { petition_id: id };
	}, [id]);

	return useCallback(
		(type: TipType) => {
			switch (type) {
				case 'description': {
					return {
						url: `${pathnameToPetition}/edit${search}#edit_description`,
						onClick: async () => {
							void track('petition_strength_card_click', { ...trackingInfo, card: 'description' });
							// TODO: refreshData();
							return Promise.resolve(true);
						},
					};
				}
				case 'media': {
					return {
						url: `${pathnameToPetition}/edit${search}#add_media`,
						onClick: async () => {
							void track('petition_strength_card_click', { ...trackingInfo, card: 'media' });
							return Promise.resolve(true);
						},
					};
				}
				case 'tags': {
					return {
						url: `${pathnameToPetition}/edit${search}#edit_topics`,
						onClick: async () => {
							void track('petition_strength_card_click', { ...trackingInfo, card: 'tags' });
							return Promise.resolve(true);
						},
					};
				}
				case 'decision_maker': {
					// show DM recommendation modal only if the fcm is enabled & petition has recommendations
					if (recommendedDecisionMakers?.length > 0) {
						const normalizedDMList = recommendedDecisionMakers
							.map((dm: RecommendedDecisionMaker) => {
								return {
									normalizedId: dm.normalizedDmId,
									canonicalPetitionTargetId: dm.canonicalPetitionTargetId,
									name: dm.name,
									title: dm.title,
									type: dm.type,
									photoUrl: dm.photo?.petitionMedium.url || null,
								};
							})
							.slice(0, MAX_RECOMMENDATIONS);
						const modalOptions = {
							petitionSlug: slug,
							petitionId: id,
							recommendedDecisionMakers: normalizedDMList,
							trackingContext: 'petition_show',
						};
						return {
							url: undefined,
							onClick: async () => {
								void track('petition_strength_card_click', { ...trackingInfo, card: 'decision_maker' });
								await openDecisionMakerRecommendationModal(modalOptions);
								return Promise.resolve(true);
							},
						};
					}
					return {
						url: `${pathnameToPetition}/edit${search}#decision_makers`,
						onClick: async () => {
							void track('petition_strength_card_click', { ...trackingInfo, card: 'decision_maker' });
							return Promise.resolve(true);
						},
					};
				}
				case 'smart_images': {
					return {
						url: `${pathnameToPetition}/smart${qs.stringify(
							{ source_location: 'petition_show', ...qs.parse(search, { ignoreQueryPrefix: true }) },
							{ addQueryPrefix: true },
						)}`,
						onClick: async () => {
							void track('petition_strength_card_click', { ...trackingInfo, card: 'smart_images' });
							return Promise.resolve(true);
						},
					};
				}
				default:
					return {
						url: undefined,
						onClick: async () => Promise.resolve(true),
					};
			}
		},
		[
			trackingInfo,
			pathnameToPetition,
			search,
			track,
			recommendedDecisionMakers,
			slug,
			id,
			openDecisionMakerRecommendationModal,
		],
	);
}
