import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { getFacebookLocale } from '@change-corgi/config/facebook';

import { usePetitionDetails } from '../../hooks/petitionDetails';

export function MetaLocaleContainer(): JSX.Element | null {
	const petition = usePetitionDetails();

	return (
		<Helmet>
			<meta
				property="og:locale"
				content={getFacebookLocale(petition.originalLocale.localeCode) || getFacebookLocale('en-US')}
			/>
		</Helmet>
	);
}
