import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useNewUpdateCta } from '../../hooks/updates/updateCta';

export function NewUpdateCtaContainer(): JSX.Element | null {
	const {
		data: { canEdit, updateHref },
	} = useNewUpdateCta();

	if (!canEdit) return null;

	return (
		<Box
			variant="bordered"
			sx={{
				borderColor: 'neutral-grey100',
			}}
			backgroundColor="neutral-grey50"
			my={16}
			p={8}
		>
			<Flex sx={{ flexDirection: ['column', null, 'row-reverse'] }}>
				<Text as="div" size="caption" sx={{ flex: ['none', null, 2] }} pl={[0, null, 8]} mb={[8, null, 0]}>
					<Translate value="fe.components.updates_feed.post_description" />
				</Text>
				<Box sx={{ flex: ['none', null, 1] }} pr={[0, null, 8]}>
					<ButtonLink
						to={updateHref}
						variant="secondaryEmphasis"
						size="medium"
						sx={{ width: '100%' }}
						data-qa="petition-updates-new-update-cta"
					>
						<Translate value="fe.components.updates_feed.post_button" />
					</ButtonLink>
				</Box>
			</Flex>
		</Box>
	);
}
