import { forwardRef } from 'react';
import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';

import { UpdateCard } from 'src/app/pages/petitionGamma/details/shared/components';
import type { PetitionUpdate } from 'src/app/pages/petitionGamma/details/shared/types';

import { Milestone } from './components/Milestone';
import { VictoryUpdate } from './components/Victory';

type Props = Readonly<{
	update: PetitionUpdate;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UpdateInner = forwardRef<any, Props>(function UpdateInner({ update }: Props, ref): JSX.Element | null {
	const { translate, translatePlural, localizeNumber } = useI18n();

	switch (update.type) {
		case 'creation':
			return (
				<Milestone
					ref={ref}
					description={translate('fe.components.updates_feed.created_this_petition', {
						petition_creator: update.displayName,
					})}
					createdAt={update.createdAt}
				/>
			);
		case 'milestone':
			return (
				<Milestone
					ref={ref}
					description={translatePlural('fe.components.updates_feed.signature_milestone_title', update.signatureCount, {
						num: localizeNumber(update.signatureCount),
					})}
					createdAt={update.createdAt}
				/>
			);
		case 'victory':
			return <VictoryUpdate ref={ref} createdAt={update.createdAt} description={update.description} />;
		default:
			return <UpdateCard ref={ref} update={update} />;
	}
});

export const Update = forwardRef(function Update({ update }: Props, ref): JSX.Element | null {
	return (
		<Box my={16}>
			<UpdateInner update={update} ref={ref} />
		</Box>
	);
});
