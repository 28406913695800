export const VoicesIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 2C8.52459 2 2 6.71305 2 13.0308C2 15.9533 3.42941 18.5608 5.67414 20.4899L4.22185 24.672C4.09239 25.0448 4.1943 25.4589 4.48201 25.729C4.76973 25.9992 5.18941 26.0748 5.55335 25.9221L11.024 23.6273C12.6783 26.5582 16.1452 28.5022 20 28.5022C21.0945 28.5022 22.1504 28.3483 23.1399 28.0627L27.076 29.9056C27.4348 30.0736 27.8589 30.014 28.1574 29.7536C28.4559 29.4932 28.5726 29.0812 28.4549 28.7029L27.4784 25.5643C29.0301 24.0358 30 22.0046 30 19.7511C30 18.597 29.7505 17.5069 29.3001 16.5133C29.753 15.4285 30 14.26 30 13.0308C30 6.71305 23.4754 2 16 2ZM4 13.0308C4 8.26887 9.116 4 16 4C22.884 4 28 8.26887 28 13.0308C28 17.7928 22.884 22.0617 16 22.0617C14.459 22.0617 12.9892 21.842 11.6403 21.4439C11.4194 21.3788 11.1828 21.3918 10.9704 21.4809L6.84639 23.2109L7.79353 20.4834C7.9351 20.0758 7.79916 19.6232 7.45635 19.3611C5.27121 17.6899 4 15.4414 4 13.0308Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9 11C9 10.4477 9.44772 10 10 10H22C22.5523 10 23 10.4477 23 11C23 11.5523 22.5523 12 22 12H10C9.44772 12 9 11.5523 9 11Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9 15C9 14.4477 9.44772 14 10 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H10C9.44772 16 9 15.5523 9 15Z"
			fill="black"
		/>
	</svg>
);
