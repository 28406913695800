import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { stripHtmlTags } from '@change-corgi/core/html';

import { usePetitionDetails } from '../../hooks/petitionDetails';

export function MetaDescriptionContainer(): JSX.Element | null {
	const { description } = usePetitionDetails();

	return (
		<Helmet>{description && <meta name="description" content={stripHtmlTags(description).substr(0, 400)} />}</Helmet>
	);
}
