import type { Position } from '../../../shared/types';

// https://wiki.openstreetmap.org/wiki/Zoom_levels#Distance_per_pixel_math
export const pickZoomLevel = (clusterSize: number, center: Position) => {
	if (clusterSize === 0) {
		return 7;
	}
	if (Math.abs(center.latitude) > 89) {
		return 4;
	}

	const earthCircumference = 40075016;
	const mathLogTwo = 0.6931471805599453;
	const adjustScaleToFitTheCircle = 3;
	const scale =
		(earthCircumference * Math.cos((Math.PI * center.latitude) / 180)) /
		(adjustScaleToFitTheCircle * clusterSize * 100000);
	return Math.log(scale) / mathLogTwo;
};
