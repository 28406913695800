import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { getContactDmConfig } from 'src/app/pages/petitionGamma/details/pageContext';

import {
	GammaPetitionDetailsPageDecisionMakers,
	type GammaPetitionDetailsPageDecisionMakersQuery,
	type GammaPetitionDetailsPageDecisionMakersQueryVariables,
} from './decisionMakers.graphql';

export type DecisionMakers = NonNullable<
	GammaPetitionDetailsPageDecisionMakersQuery['petition']
>['decisionMakersConnection']['nodes'];

export type DecisionMaker = DecisionMakers[0];

export async function getDecisionMakers(
	petitionSlugOrId: string,
	numDecisionMakers: number,
	utilityContext: UtilityContext,
): Promise<DecisionMakers> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { emailEnabled } = await getContactDmConfig(utilityContext);

	const { data } = await fetch<
		GammaPetitionDetailsPageDecisionMakersQuery,
		GammaPetitionDetailsPageDecisionMakersQueryVariables
	>({
		query: GammaPetitionDetailsPageDecisionMakers,
		variables: {
			slugOrId: petitionSlugOrId,
			numDecisionMakers,
			emailEnabled,
		},
		rejectOnError: false,
	});

	if (!data?.petition?.decisionMakersConnection) throw new Error('Unable to fetch decision makers');

	return data.petition.decisionMakersConnection.nodes;
}
