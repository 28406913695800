import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

type Result = ModelHookResult<{
	isShown: boolean;
	percentage: number;
}>;

export const useVotersPercentage = (): Result => {
	const {
		data: {
			petition: { registeredVoters },
		},
	} = usePetitionDetailsPageData();

	const voters = useMemo(() => {
		const { percentage } = registeredVoters ?? {};

		return {
			data: {
				isShown: !!percentage,
				percentage: percentage ?? 0,
			},
		};
	}, [registeredVoters]);

	return voters;
};
