import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaCreateMediaHit,
	type GammaCreateMediaHitMutation,
	type GammaCreateMediaHitMutationVariables,
} from './createMediaHit.graphql';

export async function createMediaHit(mediaLink: string, petitionId: string, utilityContext: UtilityContext) {
	const {
		gql: { fetch },
	} = utilityContext;

	const result = await fetch<GammaCreateMediaHitMutation, GammaCreateMediaHitMutationVariables>({
		query: GammaCreateMediaHit,
		variables: {
			input: {
				petitionId,
				comment: mediaLink,
				role: 'MEDIA_HIT_COMMENT',
			},
		},
		rejectOnError: false,
	});

	return result.data?.createPetitionComment;
}
