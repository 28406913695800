import { useMemo } from 'react';

import type { PetitionUpdatesList } from 'src/app/pages/petitionGamma/details/shared/types';
import { isLoaded } from 'src/app/shared/utils/async';

import { usePetitionDetails } from '../petitionDetails';

import { extractDisplayedUpdates } from './extractDisplayedUpdates';
import type { PetitionUpdatesQueryState } from './types';

export function useDisplayedUpdates(updatesState: PetitionUpdatesQueryState): PetitionUpdatesList {
	const {
		signatureState: {
			signatureCount: { displayed },
		},
	} = usePetitionDetails();

	return useMemo(() => {
		if (!isLoaded(updatesState)) {
			return [];
		}
		return extractDisplayedUpdates(updatesState.updates, displayed);
	}, [updatesState, displayed]);
}
