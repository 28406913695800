import { useCallback, useEffect } from 'react';
import type { JSX } from 'react';

import { Button, ThemeProvider } from '@change/tomato-core-ui';

import { Translate } from '@change-corgi/core/react/i18n';
import { ClientRender } from '@change-corgi/core/react/ssr/render';

import { useSignatureTracking } from '../../hooks/tracking/signature';

import { ActionButtonWrapper } from './ActionButtonWrapper';
import { useMobileSignModal, usePreloadMobileSignModal } from './MobileSignModal';
import { useMobileModalContext } from './shared/useMobileModalContext';
import { useShowSignForm } from './shared/useShowSignForm';
import { SignCtaIcon } from './SignCtaIcon';

type Props = {
	sticky: boolean;
	visible: boolean;
};

export function MobileSignButtonContainer({ sticky, visible }: Props): JSX.Element | null {
	const { trackMobileSignIntent, trackClosedModal } = useSignatureTracking();

	const context = useMobileModalContext();
	const openModal = useMobileSignModal();

	usePreloadMobileSignModal();

	const handleModalWithoutTracking = useCallback(async () => {
		await openModal(context).waitForClosed();
	}, [context, openModal]);

	const handleModal = useCallback(async () => {
		trackMobileSignIntent();
		await openModal(context).waitForClosed();
		trackClosedModal();
	}, [context, openModal, trackMobileSignIntent, trackClosedModal]);

	return (
		<>
			{/* we're looking at breakpoints to decide whether to open the modal, which cannot be done server-side */}
			<ClientRender>
				<MobileSignButtonAutoOpen onOpen={handleModalWithoutTracking} />
			</ClientRender>
			<ActionButtonWrapper visible={visible} sticky={sticky}>
				<ThemeProvider className="flex">
					<Button
						variant="primary"
						onClick={handleModal}
						leadingIcon={<SignCtaIcon width="24px" height="24px" />}
						data-qa="mobile-sign-button"
						data-testid="mobile-sign-button"
						data-gtm="ga4-sign-button"
						size="large"
						className="w-full max-w-none"
					>
						<div className="flex place-content-center place-items-center gap-3">
							<Translate value="corgi.petition_gamma.petition_sign.action" />
						</div>
					</Button>
				</ThemeProvider>
			</ActionButtonWrapper>
		</>
	);
}

function MobileSignButtonAutoOpen({ onOpen }: { onOpen: () => void }) {
	const showSignForm = useShowSignForm();

	// FIXME this should be moved somewhere else as this means that rendering MobileSignButtonContainer twice
	// will result in trying to open the modal twice
	useEffect(() => {
		if (showSignForm) {
			onOpen();
		}
		// disabling dependency check to avoid attempting to open the modal multiple times
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
}
