import { forwardRef } from 'react';
import type { JSX } from 'react';

import { useNow } from '@change-corgi/core/react/date';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = Readonly<{
	description: string;
	createdAt: string;
}>;

export const Milestone = forwardRef<HTMLElement, Props>(function Milestone(
	{ description, createdAt }: Props,
	ref,
): JSX.Element | null {
	const now = useNow();
	const { localizeRelativeTime } = useI18n();

	return (
		<Flex
			variant="bordered"
			sx={{
				borderColor: 'neutral-grey100',
			}}
			backgroundColor="neutral-grey50"
			px={16}
			py={8}
			ref={ref}
		>
			<Text as="div" size="caption" sx={{ flex: 1 }}>
				{description}
			</Text>
			<Text as="div" size="caption" sx={{ whiteSpace: 'nowrap' }}>
				{localizeRelativeTime(now, createdAt)}
			</Text>
		</Flex>
	);
});
